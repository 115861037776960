/* eslint-disable */
import PropTypes from 'prop-types';
import React, { useEffect, useReducer, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { SET_ERROR_ALERT } from '../types';

import ErrorContext from './errorContext';
import errorReducer from './errorReducer';

const ErrorState = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  // const [error, setError] = useState(null);
  // const [errorInfo, setErrorInfo] = useState(null);

  const initialState = {
    openErrorAlert: false,
    errorAlert: null,
  };

  const [state, dispatch] = useReducer(errorReducer, initialState);

  const logErrorInSession = (message) => {
    try {
      const savedError = window.localStorage.getItem('calculator_error');
      const errorArr = savedError ? JSON.parse(savedError) : [];

      errorArr.push({ date: new Date().toISOString(), message: String(message) });

      // Ограничиваем массив последних 100 ошибок
      if (errorArr.length > 100) {
        errorArr.splice(0, errorArr.length - 100);
      }

      window.localStorage.setItem('calculator_error', JSON.stringify(errorArr));
    } catch (storageError) {
      console.error('Failed to log error in localStorage:', storageError);
    }
  };

  const setErrorAlert = (errorAlert, callback = () => {}, error = null) => {
    console.log('error', callback);
    const payload = {
      errorAlert,
      openErrorAlert: !!errorAlert,
      callback,
    };

    if (errorAlert) {
      console.log(errorAlert, error ?? {});
      logErrorInSession(errorAlert);
    }

    dispatch({
      type: SET_ERROR_ALERT,
      payload,
    });
  };

  const onErrorMessageClose = () => {
    if (state?.callback) state.callback();
    setErrorAlert(null);
  };

  useEffect(() => {
    const errorHandler = (err) => {
      setHasError(true);
      const errorMessage = err?.message || err?.toString() || 'Unknown error';
      logErrorInSession(errorMessage);
    };

    window.addEventListener('error', errorHandler);
    return () => {
      window.removeEventListener('error', errorHandler);
    };
  }, []);

  useEffect(() => {
    if (hasError)
      setErrorAlert('Something went wrong. Please refresh the page or try again later.');
  }, [hasError]);

  return (
    <ErrorContext.Provider value={{ setErrorAlert }}>
      <>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={state.openErrorAlert}
          onClose={() => onErrorMessageClose()}
        >
          <Alert
            onClose={() => onErrorMessageClose()}
            severity="error"
            sx={{ width: '100%', backgroundColor: '#D32F2F', color: '#ffffff' }}
          >
            {state.errorAlert}
          </Alert>
        </Snackbar>
        {children}
      </>
    </ErrorContext.Provider>
  );
};

ErrorState.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorState;
