/* eslint-disable */
import clsx from 'clsx';
import PropTypes, { string } from 'prop-types';
import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { Grid, Button, Tooltip } from '@mui/material';
import { CalculatorsContext, DraftContext, ErrorContext } from '../../../context';

import Form from '../../../components/form/Form';
import Pagination from '../../../components/pagination/Pagination';

import styles from './index.module.scss';
import CalculationCost from '../../../components/calculationCost/CalculationCost';
import PreliminaryResults from '../Carbon/components/PreliminaryResults';
import CustomCo2Item from './components/CustomCo2Item';
import QuestionSharing from '../../../components/questionSharing/QuestionSharing';

import { CheckedIcon, InfoIcon, HelpIcon, FileAddIcon, AddIcon } from 'tt-ui-kit';
import { color } from 'd3';
import MuiSelect from '../../../components/muiSelect/MuiSelect';

const tooltipText = `Please add the components used in this project.
For each component, specify the amount you need.
Select supplier for a component from the list or add them manually. Invite the supplier to the platform to confirm their participation in your project. This will provide greater transparency for your project.

Carbon Footprint (Optional):
If you have information about a component's carbon footprint (environmental impact), you can provide it here.

If you're unsure about any details, feel free to invite colleagues or teammates to collaborate by clicking "Invite to answer."
`;

const CustomCo2 = ({}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { idx } = useParams();

  const [scopeIndex, setScopeIndex] = useState(null);
  const [preliminaryModalOpened, setPreliminaryModalOpened] = useState(false);

  const { draftId, draft } = useContext(DraftContext);
  const { config, customCO2Answers, chartData, setAnswersData, isAnyAnswerExists, questionsMap } =
    useContext(CalculatorsContext);

  const activities = [...(customCO2Answers?.[scopeIndex]?.[id] ?? [])];

  const setAllQuestionModalOpened = () => {};

  useEffect(() => {
    setScopeIndex(idx - 1);
  }, []);

  const changeActivity = () => {};
  const openDeleteModal = () => {};
  const getItemsList = () => {};

  const onBackClick = () => {
    const index = questionsMap?.carbon?.scopeMap[scopeIndex]?.end;
    navigate(`/calculators/carbon/${index}`);
  };

  const onNextClick = () => {
    setPreliminaryModalOpened(true);
  };

  const goForward = () => {
    const index = questionsMap?.carbon?.scopeMap[scopeIndex]?.end;
    if (index == questionsMap?.carbon?.end) {
      navigate(`/buy_credits/${draftId}`);
    } else navigate(`/calculators/carbon/${index + 2}`);
  };

  const closePreliminaryModal = () => {
    setPreliminaryModalOpened(false);
    goForward();
  };

  const factorList = [
    {
      id: 1,
      name: "Item"
    }
  ]

  return (
    <>
      {preliminaryModalOpened && (
        <PreliminaryResults
          opened={preliminaryModalOpened}
          closeModalFunc={closePreliminaryModal}
          draftId={draftId}
          scopeIndex={scopeIndex}
        />
      )}
      <div className={styles.formContainer}>
        <div>
          <Form
            headerContent={
              <>
                <div className={clsx(styles.headerContainer, styles.companyHeaderContainer)}>
                  <p>Items/Components</p>
                  <span>
                    <Tooltip tooltipText={tooltipText} enterTouchDelay={0}>
                      <InfoIcon className={clsx(styles.infoIcon, styles.topIcon)} />
                    </Tooltip>
                  </span>   
                </div>
                <div className={styles.uploadLink}>
                  <p>Upload the list of items</p> 
                </div>
                <div className={styles.inviteBlock}>
                  <QuestionSharing
                    calculatorId={draftId}
                    categoryCode={scopeIndex}
                    questionId={scopeIndex}
                  />
                  <HelpIcon className={styles.helpIcon} />
                </div>
              </>
            }
          >
            {activities &&
              activities.map((activity, indx) => (
                <CustomCo2Item
                  key={indx}
                  activity={activity}
                  index={indx}
                  onChange={changeActivity}
                  onRemove={openDeleteModal}
                  getItemsList={getItemsList}
                  isLast={indx === activities.length - 1}
                />
              ))}
            <div className={styles.itemsTitle}>
              <p>Item/Component №1 </p>
            </div>
            <div className={styles.inputsBlock}>
              <div className={styles.inputRow}>
                <MuiSelect
                  className={clsx(styles.input, styles.wide)}
                  label="Items/Components"
                  name="ukGhgCollectionId"
                  value={''}
                  renderValue={(value) => factorList.find((item) => item.id === value)?.name}
                  items={factorList?.map((item) => item.id) ?? []}
                />
                <TextField
                  id={'options'}
                  className={styles.costInput}
                  label="Quantity"
                  data-type="unit_quantity"
                  type="number"
                  onKeyDown={(e) => {
                    if (
                      e.key === ' ' ||
                      (!/[0-9]/.test(e.key) &&
                        ![
                          'Backspace',
                          'Delete',
                          'ArrowLeft',
                          'ArrowRight',
                          'Tab',
                          'Enter',
                        ].includes(e.key))
                    ) {
                      e.preventDefault();
                    }
                  }}
                  InputProps={{
                    style: { appearance: 'textfield' },
                    inputProps: {
                      min: 0,
                      step: 'any',
                      onWheel: (e) => {
                        e.target.blur();
                      },
                    },
                    endAdornment: (
                      <InputAdornment position="start">
                        <>
                          <Select
                            style={{ display: 'flex' }}
                            sx={{
                              '.MuiOutlinedInput-notchedOutline': {
                                border: '0 !important',
                              },
                            }}
                            value={'kg'}
                            renderValue={() =>
                              <div style={{ display: 'flex' }}>
                                <p>kg</p>
                              </div>
                            }
                          >
                            <MenuItem>
                              <p>kg</p>
                            </MenuItem>
                          </Select>
                        </>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <MuiSelect
                  className={clsx(styles.input, styles.wide)}
                  label="Supplier"
                  name="ukGhgCollectionId"
                  value={''}
                  renderValue={(value) => factorList.find((item) => item.id === value)?.name}
                  items={factorList?.map((item) => item.id) ?? []}
                />
                <TextField
                  id={'options'}
                  className={styles.costInput}
                  label="Component Carbon Footprint"
                  data-type="footprint"
                  type="number"
                  onKeyDown={(e) => {
                    if (
                      e.key === ' ' ||
                      (!/[0-9]/.test(e.key) &&
                        ![
                          'Backspace',
                          'Delete',
                          'ArrowLeft',
                          'ArrowRight',
                          'Tab',
                          'Enter',
                        ].includes(e.key))
                    ) {
                      e.preventDefault();
                    }
                  }}
                  InputProps={{
                    inputProps: {
                      min: 0,
                      step: 'any',
                      onWheel: (e) => {
                        e.target.blur();
                      },
                    },
                    endAdornment: (
                      <InputAdornment position="start">
                        <>
                          <Select
                            style={{ display: 'flex' }}
                            sx={{
                              '.MuiOutlinedInput-notchedOutline': {
                                border: '0 !important',
                              },
                            }}
                            value={'kg'}
                            renderValue={() =>
                              <div style={{ display: 'flex' }}>
                                <p>kg CO2</p>
                              </div>
                            }
                          >
                            <MenuItem>
                              <p>kg</p>
                            </MenuItem>
                          </Select>
                        </>
                      </InputAdornment>
                    ),
                  }}
                />
                <div className={styles.checkboxContainer}>
                  <input type="checkbox" id="offset" />
                  <label for="offset">It has been offset</label>
                </div>
              </div>
            </div>
            <div className={styles.addBlock}>
              <div className={styles.conformBlock}>
                <FileAddIcon style={{ width: 24, height: 28 }} />
                <p>Confirmation docs</p>
              </div>
              <div>
                <Button variant="outlined" className={styles.addButton}>
                  <AddIcon className={styles.addIcon}/>
                  Add component
                </Button>
              </div>
            </div>
          </Form>
          <div className={styles.infoWrapper}>
            <div className={styles.lastupdateInfo}>
              <CheckedIcon className={styles.checkIcon} />
              <p>Latest update: 12.03.2024</p>
            </div>
            {questionsMap?.carbon && scopeIndex != null && (
              <Pagination
                activePage={questionsMap?.carbon?.scopeMap[scopeIndex]?.end + 1}
                pagesCount={questionsMap?.carbon?.scopeMap[scopeIndex]?.end + 1}
              />
            )}
            <div className={styles.productInfo}>
              <div className={styles.currentPlan}>
                <p>Your current plan:</p>
                <p>Free.</p>
              </div>
              <div className={styles.costInfo}>
                <p>The calculation cost:</p>
                <p className={styles.priceText}>$53</p>
                <div>
                  <HelpIcon className={styles.helpIcon} />
                </div>
              </div>
            </div>
          </div>



          <CalculationCost />
        </div>
        <div className={styles.navigation}>
          <Button variant="outlined" onClick={onBackClick}>
            BACK
          </Button>
          <Button variant="outlined" onClick={() => setAllQuestionModalOpened(true)}>
            view all questions
          </Button>
          

          <Button variant="contained" onClick={onNextClick}>
            {questionsMap?.carbon?.scopeMap[scopeIndex]?.end == questionsMap?.carbon?.end
              ? 'FINISH'
              : 'NEXT'}
          </Button>
        </div>
      </div>
    </>
  );
};

CustomCo2.propTypes = {
  // scope: PropTypes.string,
  // setQuestionIndex: PropTypes.func,
};

CustomCo2.defaultProps = {
  // setQuestionIndex: () => {},
};

export default CustomCo2;
