import React, { useState } from 'react';
import { Modal } from 'tt-ui-kit';

import SignUpModal from './SignUpModal';
import { SignInModal } from './SignInModal';

const SectionsSwitcher = ({
  mode,
  setMode,
  setOpen,
  setTitle,
  closeModal,
  loginHandler,
  agreementSchema,
  signInWithGoogle,
}) => {
  switch (mode) {
    case 'in':
      setTitle('Please sign in to proceed');
      return (
        <SignInModal
          signInWithGoogle={signInWithGoogle}
          setMode={setMode}
          closeModal={closeModal}
          handler={loginHandler}
          setOpen={setOpen}
        />
      );
    case 'up':
      setTitle('Sign Up to proceed');
      return (
        <SignUpModal
          setMode={setMode}
          closeModal={closeModal}
          agreementSchema={agreementSchema}
          setOpen={setOpen}
        />
      );
    default:
      return (
        <SignUpModal
          signInWithGoogle={signInWithGoogle}
          setMode={setMode}
          closeModal={closeModal}
          handler={loginHandler}
        />
      );
  }
};

const SignInUpModal = ({ open, setOpenSignUp }) => {
  const [mode, setMode] = useState('in');
  const [title, setTitle] = useState('Sign up');

  const signInWithGoogle = () => {
    const url = `${process.env.REACT_APP_SSO}/login/google?registrationType=individual&sign_in=true`;
    window.location.replace(url);
  };

  return (
    <div>
      <Modal title={title} open={open} onClose={() => setOpenSignUp(false)}>
        <SectionsSwitcher
          mode={mode}
          setMode={setMode}
          setTitle={setTitle}
          signInWithGoogle={signInWithGoogle}
          setOpen={setOpenSignUp}
        />
      </Modal>
    </div>
  );
};

export default SignInUpModal;
