import React, { useEffect, useState, useCallback } from 'react';
import clsx from 'clsx';
import { Button, Input, Modal, InputTextArea, ANTD_COMPONENTS } from 'tt-ui-lib/core';
import { PartialLoader } from 'tt-ui-kit';
import { ReactComponent as GrayPlusIco } from '../../assets/icons/gray-plus.svg';
import styles from './InviteToAnswer.module.scss';
import { InviteCalcUser } from '../inviteCalcUser/InviteCalcUser';
import UserBlockSimple from '../userBlockSimple/UserBlockSimple';
import { searchUsersAndInvitations } from '../../api/rest/list';

const { Radio, Row, Col } = ANTD_COMPONENTS;

const { TextArea } = Input;

const tooltipText = `info`;
const aboutInvite = `You're about to invite your colleague/partner to the TransparenTerra Platform.
  TransparenTerra will send this request on your behalf to join TransparenTerra with a "Individual" role.
  Once the invitation is accepted and your colleague/partner completes the sign-up, you will receive a notification.`;

const InviteToAnswer = (props) => {
  const {
    disabled,
    btnType,
    btnText,
    title,
    onCloseCallback = () => {},
    roleQuery,
    sendInviteQuery,
  } = props;

  const [users, setUsers] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [message, setMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timeoutID, setTimeoutID] = useState(null);

  const [queryFilter, setQueryFilter] = useState({
    page: 1,
    first: 10,
    q: '',
  });

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setInputValue(value);
    if (timeoutID) {
      clearTimeout(timeoutID);
    }
    const timeout = setTimeout(() => {
      setQueryFilter((current) => ({
        ...current,
        page: 1,
        q: value,
      }));
      setUsers([]);
    }, 500);
    setTimeoutID(timeout);
  };

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollHeight - scrollTop === clientHeight) {
      setQueryFilter((current) => ({
        ...current,
        page: current.page + 1,
      }));
    }
  };

  // Очищаем состояние и перезапрашиваем данные при открытии
  const onOpen = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        searchUsersAndInvitations(queryFilter).then((resp) => {
          const { success, data } = resp.data;
          if (success) {
            setUsers((current) => [
              ...current.filter((el) => !data?.items?.some((d) => d.id === el.id)),
              ...data.items,
            ]);
            setHasMore(data.hasMore);
          }
        });
      } catch (error) {
        // setErrorAlert('Failed to load answers:', null, error);
      }
    };

    if (!queryFilter) return;
    setLoading(true);
    fetchUsers().then(() => setLoading(false));
  }, [queryFilter]);

  const onClose = () => {
    setIsModalOpen(false);
    setSelectedUserId(false);
  };

  const onNextStep = () => {
    if (onCloseCallback) {
      const addressee = users.find((el) => el.id === selectedUserId);
      onCloseCallback({
        message,
        invitation_id: addressee.type === 'invitation' ? addressee.id : null,
        user_id: addressee.type === 'user' ? addressee.id : null,
        addressee: {
          email: addressee?.email,
          first_name: addressee?.firstName,
          last_name: addressee?.lastName,
        },
      });
    }
    onClose();
  };

  const addNewInvitation = (invitation) => {
    setUsers((prev) => [invitation, ...prev]);
    setSelectedUserId(invitation.id);
  };

  return (
    <>
      <Button
        type={btnType ?? 'link'}
        onClick={onOpen}
        disabled={disabled}
        className={styles.docButton}
      >
        <GrayPlusIco />
        {btnText ?? 'Invite to answer'}
      </Button>
      {isModalOpen && (
        <Modal
          open={isModalOpen}
          onClose={() => onClose(false)}
          onClick={(e) => e.stopPropagation()}
          title={<div style={{ paddingRight: '50px' }}>{title ?? 'Invite to answer'}</div>}
          className={styles.modal}
          closeOnlyByControls
        >
          <div className={styles.modalContent}>
            <div className={styles.modalAbout}>{aboutInvite}</div>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={12}>
                <Input
                  placeholder="Поиск пользователя"
                  value={inputValue}
                  onChange={handleSearchChange}
                />
              </Col>
              <Col xs={24} sm={24} md={12} className={styles.modal_inviteButton}>
                <InviteCalcUser
                  title="Invite your colleagues/partners to contribute to this question"
                  onClose={() => setIsModalOpen(false)}
                  roleQuery={roleQuery}
                  sendInviteQuery={sendInviteQuery}
                  returnInvitation={addNewInvitation}
                  onCloseCallback={onOpen}
                />
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24}>
                {users.length ? (
                  <div className={styles.radioWrapper} onScroll={handleScroll}>
                    <Radio.Group
                      onChange={(e) => setSelectedUserId(e.target.value)}
                      value={selectedUserId}
                      style={{ width: '100%', border: 'none' }}
                    >
                      {users.map((invitation) => (
                        <Radio
                          key={invitation.id}
                          value={invitation.id}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '8px 0',
                          }}
                        >
                          <UserBlockSimple user={invitation} />
                        </Radio>
                      ))}
                    </Radio.Group>
                  </div>
                ) : (
                  <div style={{ padding: '10px 0' }}>No users found</div>
                )}
                {loading && <div>Loading...</div>}
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24}>
                <InputTextArea
                  style={{ padding: '10px' }}
                  inputMode="text"
                  placeholder="Message"
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                />
              </Col>
            </Row>
            <div className={styles.buttonWrapper}>
              <Button className={styles.modal_button} type="default" onClick={onClose}>
                Cancel
              </Button>
              <Button
                className={styles.modal_button}
                disabled={!selectedUserId}
                type="primary"
                onClick={onNextStep}
              >
                Request a pesponse
              </Button>
            </div>
          </div>
          {setLoading && (
            <div>
              <PartialLoader />
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default InviteToAnswer;
