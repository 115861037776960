import clsx from 'clsx';
import React, { useState, useEffect, useRef } from 'react';

import AccordionGroup from '../../components/accordionGroup/AccordionGroup';
import LazyImage from '../../components/lazyImage/LazyImage';

import styles from './About.module.scss';
import { ReactComponent as AboutSvg } from '../../assets/icons/about.svg';
import Header from '../../components/header/Header';

const About = ({ setOpenSignUp }) => {
  let timer = null;

  const infoEl = useRef(null);
  const containerEl = useRef(null);
  const [playMode, setPlayMode] = useState('');
  const [active, setActive] = useState({ id: -1, name: null });
  const [expanded, setExpanded] = useState(true);
  const [scrolling, setScrolling] = useState(false);

  const esgItems = [
    {
      name: 'ESG Excellence',
      content: `ESG Excellence has been developed based on Refinitiv&apos;s methodology to compare
      your company current sustainability level to the other companies listed on TransparenTerra.`,
    },
    {
      name: 'Assessment of corporate governance',
      nested: true,
      content: `Sustainable corporate governance is assessed by&nbsp;monitoring corporate behavior. The 
      process includes measuring business ethics, anti-competitive practices, corruption, taxation, and 
      transparency for&nbsp;stakeholders.`,
    },
    {
      name: 'Environmental performance',
      nested: true,
      content: `Environmental benchmarks include a&nbsp;company&apos;s energy and water consumption, 
      amount of&nbsp;waste and pollution generated, and conservation of&nbsp;natural resources and 
      biodiversity. Benchmarks can also be used to&nbsp;assess environmental risks the&nbsp;company faces and 
      how the&nbsp;company is managing those risks.`,
    },
    {
      name: 'Social impact',
      nested: true,
      content: `Sustainable companies create long-term value by&nbsp;implementing business strategies 
      that focus on&nbsp;ethical, social, and community issues to&nbsp;promote longevity, transparency, and 
      appropriate employee development.`,
    },
    {
      name: 'Carbon Footprint Calculator',
      content: `This tool allows you to&nbsp;calculate your organization&apos;s carbon footprint. 
      The calculator is based on&nbsp;the&nbsp;widely accepted GHG Carbon Footprint Protocol and consists 
      of&nbsp;two parts: a&nbsp;Company calculator and a&nbsp;Product calculator.`,
    },
    {
      name: 'ESG Benchmark',
      content: `The ESG Benchmark is&nbsp;more suitable for&nbsp;larger companies that need a&nbsp;comprehensive
      sustainability analysis in&nbsp;their market sector. It&nbsp;helps understand the&nbsp;company&apos;s sustainability
      level and its&nbsp;comparison to&nbsp;global benchmarks. This understanding can guide companies in&nbsp;implementing
      business strategies focusing on&nbsp;ethical, social, and&nbsp;community issues to&nbsp;promote longevity,
      transparency, and&nbsp;appropriate employee development.`,
    },
    // {
    //   name: "Individual calculator",
    //   content: `The South Pole TT calculator is&nbsp;offered as an&nbsp;additional option
    //   for&nbsp;individuals and is&nbsp;developed by&nbsp;South Pole.`,
    // },
  ];

  const menuItems = [
    {
      name: 'Esg',
      text: 'What is the TT ESG calculator?',
      content: (
        <>
          <p>
            The management of&nbsp;ESG (environmental, social, and governance) factors is proving
            to&nbsp;be as&nbsp;critical as&nbsp;a&nbsp;company&apos;s financial performance.
            An&nbsp;ESG rating estimates the&nbsp;company&apos;s long-term sustainability and future
            impact on&nbsp;society and the&nbsp;environment. Each rating agency relies
            on&nbsp;a&nbsp;different set of&nbsp;indicators and metrics to&nbsp;produce its reports.
          </p>
          <p>
            The calculator is&nbsp;based on a&nbsp;robust methodology and a&nbsp;vast database,
            which allows it to&nbsp;factor in&nbsp;every possible indicator of a&nbsp;company&apos;s
            ESG. The service includes a&nbsp;set of&nbsp;calculators for estimating ESG measures,
            such as&nbsp;the ESG Benchmark, ESG Excellence, and Carbon.
          </p>
          <AccordionGroup items={esgItems} />
        </>
      ),
    },
    {
      name: 'Carbon',
      text: 'The Carbon Footprint Calculator',
      content: (
        <>
          <p>
            Each activity has its own CO2 equivalent emission amount. The&nbsp; parameters
            for&nbsp;each activity are based on&nbsp;data and formulas of&nbsp; the&nbsp;UK
            Government&apos;s GHG Conversion Factors for&nbsp;Company Reporting. If the&nbsp;user
            chooses an&nbsp;industry and business category associated with the creation
            of&nbsp;a&nbsp;product, the&nbsp;first step is to&nbsp;calculate the&nbsp; carbon
            footprint of&nbsp;the&nbsp;product. The&nbsp;user should fill in&nbsp;the&nbsp;
            calculator&apos;s parameters for each of his products. Then the user needs to&nbsp;fill
            in&nbsp;the&nbsp;tables for&nbsp;all the&nbsp;specified types of&nbsp;the&nbsp;
            company&apos;s activities. The entered data are&nbsp;multiplied by&nbsp;the&nbsp; CO2
            equivalent emission parameters and summed&nbsp;up.
          </p>
          <p>
            At&nbsp;each calculation step, the&nbsp;user can upload a&nbsp;document that confirms
            the&nbsp;entered data. This will help experts check the&nbsp; calculation results and
            approve them or&nbsp;not.
          </p>
          <p>
            As&nbsp;a&nbsp;result, the&nbsp;business owner receives the&nbsp;carbon footprint
            of&nbsp; both his&nbsp;company and all his&nbsp;products.
          </p>
          <p>
            After making the&nbsp;calculations, the&nbsp;user is advised to&nbsp;contact a&nbsp;
            nearby expert to&nbsp;verify the&nbsp;numbers.
          </p>
          <p>Also, the&nbsp;system prompts the&nbsp;user to&nbsp;consider two options:</p>
          <p>
            - to&nbsp;compensate for&nbsp;their current CO2 footprint by&nbsp;purchasing
            carbon-offset certificates from&nbsp;licensed providers and traders, or
          </p>
          <p>
            - to&nbsp;contact one of&nbsp;the&nbsp;experts in&nbsp;sustainability to&nbsp;improve
            the&nbsp; user&apos;s ESG score.
          </p>
          <p>
            The&nbsp;user can enter the&nbsp;emission source data, and then fills in&nbsp;the&nbsp;
            data on&nbsp;the&nbsp;amount of&nbsp;the&nbsp;ingredients that is&nbsp;included
            in&nbsp;the&nbsp; product.
          </p>
          <p>
            Once a&nbsp;user fills in&nbsp;data for&nbsp;the&nbsp;one ingredient, he can adds
            a&nbsp;new row for&nbsp;another one. He does such a&nbsp;procedure until all the&nbsp;
            ingredients are added.
          </p>
          <p>
            To&nbsp;demonstrate transparency and openness of&nbsp;your business you can submit
            the&nbsp;results of&nbsp;ESG score to&nbsp;the&nbsp;blockchain. This makes your business
            more attractive to&nbsp;third parties.
          </p>
        </>
      ),
    },
    {
      name: 'Methodology',
      text: 'TT ESG Methodology',
      content: (
        <>
          <p>
            To&nbsp;compare companies in&nbsp;the&nbsp;ESG format, a&nbsp;set of&nbsp;logical and
            numerical data is&nbsp;used. The&nbsp;data refer to&nbsp;three main segments
            of&nbsp;the&nbsp; format: Environmental, Social, and Governance.
          </p>
          <p>
            Companies are compared by&nbsp;converting the&nbsp;data into a&nbsp;ranking. The&nbsp;
            company’s overall rating is&nbsp;obtained from the&nbsp;sum of&nbsp;the&nbsp;values
            for&nbsp; each ESG segment, which, by&nbsp;analogy, are&nbsp;obtained from the&nbsp;sums
            of&nbsp; the&nbsp;values of&nbsp;the&nbsp;methodology categories.
          </p>
          <p>
            The&nbsp;sum of&nbsp;the&nbsp;data for&nbsp;that category in&nbsp;percentiles
            is&nbsp;multiplied by&nbsp;its weight for&nbsp;the&nbsp;industry group to&nbsp;obtain
            the&nbsp;value of&nbsp;the&nbsp; company categories.
          </p>
          <p>
            Percentiles are determined by the&nbsp;ratio of&nbsp;the&nbsp;sum
            of&nbsp;the&nbsp;number of&nbsp; companies with a&nbsp;value below/above
            the&nbsp;specified value (depending on&nbsp; polarity) and half of&nbsp;the&nbsp;similar
            ones, to&nbsp;all companies from&nbsp;these series. For&nbsp;the&nbsp;Environmental and
            Social segments, percentiles are calculated within industry groups;
            for&nbsp;the&nbsp;Governance segment, they are calculated within the&nbsp;country
            of&nbsp;registration.
          </p>
          <p>
            To&nbsp;determine the&nbsp;category weights of&nbsp;the&nbsp;Environmental and Social
            segments, the&nbsp;average value of&nbsp;the&nbsp;decile rank of&nbsp;the&nbsp;relative
            median of&nbsp;the&nbsp;category is first determined. The&nbsp;resulting category value
            is then referred to&nbsp;the&nbsp;sum of&nbsp;the&nbsp;values of&nbsp;all 21 categories.
            Thus, an&nbsp;intermediate weighting factor is obtained. By multiplying the&nbsp;ratio
            of&nbsp;the&nbsp;given weight coefficient by&nbsp;the&nbsp;sum of&nbsp; the&nbsp;weight
            coefficients of&nbsp;the&nbsp;given category, we obtain the&nbsp;weight
            of&nbsp;the&nbsp;category.
          </p>
          <p>
            In&nbsp;the&nbsp;Governance segment, the&nbsp;category weight of&nbsp;every industry
            group is considered equally important. Thus, it&nbsp;is calculated using the&nbsp;ratio
            of&nbsp;the&nbsp;number of&nbsp;category data to&nbsp;the&nbsp;number of&nbsp;data
            for&nbsp; the&nbsp;entire segment, multiplied by&nbsp;the&nbsp;product
            of&nbsp;the&nbsp;number of&nbsp;segment categories by&nbsp;the&nbsp;average category
            weight according to&nbsp;the&nbsp; methodology.
          </p>
          <p>
            Similarly, the&nbsp;category Social Impacts on&nbsp;Communities is accepted
            as&nbsp;equally important for&nbsp;any industrial group.
          </p>
          <p>
            The Carbon Calculator&nbsp;is based on the&nbsp;UK&nbsp;government&apos;s conversion
            factors for&nbsp;companies reporting greenhouse&nbsp;gas emissions. It&nbsp;includes
            Scope&nbsp;1, Scope&nbsp;2, Scope&nbsp;3.
          </p>
          <p>
            The ESG Excellence use to compare your&nbsp;company current sustainability level with
            other companies listed on&nbsp;TransparenTerra. The environmental part
            of&nbsp;this&nbsp;calculator also includes the&nbsp;professional Carbon Calculator.
          </p>
          <p>
            Once a company enters its data, it&apos;s assigned a rating from 0&nbsp;to&nbsp;100
            to&nbsp;enable comparisons with other&nbsp;users of the&nbsp;TransparenTerra Platform.
          </p>
          <p>
            The ESG Benchmark allows large companies from various industries to&nbsp;conduct
            an&nbsp;in-depth corporate self-assessment. This data will help companies know their
            sustainability score and improve their industry.
          </p>
          <p>
            After receiving the score, companies can see themselves on&nbsp;a&nbsp;list of other
            companies that have completed the&nbsp;self-assessment on&nbsp;TransparenTerra.
          </p>
        </>
      ),
    },
    {
      name: 'EsgCalculator',
      text: 'Who can use TT ESG Calculator?',
      content: (
        <>
          <p>
            Owners of&nbsp;the&nbsp;companies in&nbsp;various industries and areas can use this
            product to&nbsp;determine the&nbsp;environmental performance and climate impact
            of&nbsp;their business. The product can be used for&nbsp;a&nbsp; company&apos;s
            self-assessment and as&nbsp;a&nbsp;professional tool for&nbsp; independent third-party
            examination.
          </p>
          <p>
            A&nbsp;business user can calculate their ESG score and Carbon Footprint
            an&nbsp;unlimited number of&nbsp;times, thus demonstrating the&nbsp;evolution of&nbsp;
            their sustainability level. By&nbsp;using the&nbsp;tool, the&nbsp;user grants access
            to&nbsp;and use of&nbsp;his public data without restriction for&nbsp; independent
            assessment and analysis by&nbsp;third-party participants on&nbsp; the&nbsp;platform
            or&nbsp;the&nbsp;platform itself.
          </p>
        </>
      ),
    },
    {
      name: 'Features',
      text: 'Features and benefits of the TT ESG calculator',
      content: (
        <>
          <p>
            <b>Availability.</b> The&nbsp;TT ESG Calculator provides high-quality ESG calculations
            for&nbsp;small and medium-sized companies.
          </p>
          <p>
            <b>Visible improvement.</b> Perform regular calculations, generate reports, and track
            your progress toward environmental goals.
          </p>
          <p>
            <b>Depth of detail.</b> The&nbsp;TT ESG Calculator offers a&nbsp;wide range of&nbsp;
            trackable features and a&nbsp;flexible service architecture suitable for&nbsp;
            a&nbsp;variety of&nbsp;industries and sizes.
          </p>
          <p>
            <b>Trust Management Tool.</b> Send your ESG calculations to&nbsp;the&nbsp; blockchain
            and improve your company&apos;s reputation through open and transparent policies.
          </p>
          <p>
            <b>Security.</b> The&nbsp;service ensures reliable data protection: all input data and
            calculation results are encrypted with one-way encryption.
          </p>
          <p>
            <b>Total environmental friendliness.</b> The&nbsp;TT ESG Calculator runs
            on&nbsp;a&nbsp;sustainable data infrastructure and a&nbsp;green blockchain.
          </p>
          <p>
            <b>A solid foundation for your work.</b> You can get sound advice on&nbsp; sustainable
            development and explore your options for&nbsp;certification based on&nbsp;your
            preliminary calculations and a&nbsp;detailed picture of&nbsp; your company&apos;s
            performance.
          </p>
        </>
      ),
    },
  ];

  const onItemClick = (e) => {
    const newActive = e.target;

    setActive(
      newActive.name !== active.name ? { id: newActive.dataset.id, name: newActive.name } : active
    );
  };

  const changeExpanded = (newExpanded) => {
    setExpanded(newExpanded);
  };

  useEffect(() => {
    const items = document.getElementsByClassName('accordionItem');
    if (items.length > 0) {
      Array.from(items).forEach((item) => item.addEventListener('click', onItemClick));
    }
    return () => {
      Array.from(items).forEach((item) => item.removeEventListener('click', onItemClick));
    };
  });

  useEffect(() => {
    setPlayMode('playing');
    setTimeout(() => {
      setPlayMode('played');
      setActive({ id: 0, name: menuItems[0].name });
    }, 1000);
  }, []);

  const onScroll = (e) => {
    if (scrolling) return;

    setScrolling(true);

    // scrolling up
    if (e.deltaY < 0 && active.id > 0) {
      setActive({ id: active.id - 1, name: menuItems[active.id - 1].name });
      containerEl.current.scrollTop = 0;
      infoEl.current.scrollTop = 0;
    }
    // scrolling down
    else if (e.deltaY > 0 && active.id < menuItems.length - 1) {
      setActive({ id: active.id + 1, name: menuItems[active.id + 1].name });
      containerEl.current.scrollTop = 0;
      infoEl.current.scrollTop = 0;
    }

    clearTimeout(timer);
    timer = setTimeout(() => {
      setScrolling(false);
    }, 1000);
  };

  const onScrollInfo = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      <Header setOpenSignUp={setOpenSignUp} />
      <main>
        <div
          className={clsx(styles.container, styles[playMode])}
          onWheel={onScroll}
          ref={containerEl}
        >
          <LazyImage
            className={clsx(
              styles.lazyImage,
              'lazy',
              active.name ? styles.previous : styles.active
            )}
            bg="aboutStart.jpg"
          />
          {menuItems.map((item, i) => (
            <LazyImage
              key={item.name}
              className={clsx(
                styles.lazyImage,
                'lazy',
                active.id === i ? styles.active : '',
                active.id > i ? styles.previous : '',
                active.id < i ? styles.next : ''
              )}
              bg={`about${item.name}.jpg`}
            />
          ))}
          <div className={styles.accordionArea}>
            <AccordionGroup
              className={styles.accordionGroup}
              onAccordionClick={changeExpanded}
              items={[
                {
                  name: 'About',
                  expanded,
                  content: `<ul>${menuItems
                    .map(
                      (item, i) =>
                        `<li>
                          <a name=${item.name} data-id=${i} class=${
                            active.name === item.name ? '"accordionItem active"' : 'accordionItem'
                          } >${item.text}</a>
                    </li>`
                    )
                    .join('')}</ul>`,
                },
              ]}
            />
          </div>
          <div className={styles.infoArea}>
            <div className={styles.circle}>
              <AboutSvg className={styles.aboutSvg} />
            </div>

            <div className={styles.info} ref={infoEl}>
              <div className={styles.circle}>
                <AboutSvg className={styles.aboutSvg} />
              </div>
              <div name="infoBlock" onWheel={onScrollInfo}>
                <h1>{menuItems.filter((item) => item.name === active.name)[0]?.text}</h1>
                {menuItems.filter((item) => item.name === active.name)[0]?.content}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default About;
