import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { GET_PRO_LOGIN } from '../../../api/apollo/api';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import {
  Button,
  InputAdornment,
  TextField,
  VisibilityIcon,
  VisibilityOffIcon,
  GoogleIcon,
} from 'tt-ui-kit';
import { useMutation } from '@apollo/client';
import Cookies from 'js-cookie';

import styles from './SignInModal.module.scss';

const schema = yup.object({
  username: yup
    .string()
    .required('Please enter your email address')
    .email('Please enter a valid email address, e.g. joe@mail.com'),
  password: yup.string().required('Please enter your password'),
});

export const SignInModal = ({ setMode, handler, signInWithGoogle, setOpen }) => {
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(false);

  const [getLogin] = useMutation(GET_PRO_LOGIN, {
    variables: { input: {} },
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const loginHandler = async (data, setErrorData, onFinish) => {
    const res = await getLogin({
      variables: {
        input: data,
      },
      // onError: (graphQLErrors) => {
      //   if (graphQLErrors) {
      //     const errorData = `\n${graphQLErrors}`.split('Error:')[1];
      //     if (errorData.includes('Your account is currently blocked')) {
      //       setIsBlockedModalOpen(true);
      //     } else {
      //       setErrorData(errorData);
      //     }
      //   }
      // },
    });

    if (!res.errors && res.data.login.access_token) {
      const domainValue = process.env.REACT_APP_DOMAIN;
      Cookies.set('access_token', res.data.login.access_token, {
        domain: domainValue,
        secure: true,
      });
      Cookies.set('refresh_token', res.data.login.refresh_token, {
        domain: domainValue,
      });
      Cookies.set('userId', res.data.login.user.id, { domain: domainValue, secure: true });

      window.location.reload();

      // if (redirectAfterLoginTo) {
      //   window.location.href = redirectAfterLoginTo;
      // } else if (reloadAfterLogin) {
      //   window.location.reload();
      // } else {
      //   window.location.href = '/dashboard/marketplace';
      // }
      // closeModal();
    }

    setOpen(false);

    onFinish();
  };

  const onChangeInput = (name, value) => {
    setValue(name, value);
  };

  const onSubmit = async (data) => {
    setError('');
    setSubmitDisable(true);

    await loginHandler(data, setError, () => {
      setSubmitDisable(false);
    });
  };

  return (
    <div className={styles.formWrapper}>
      <form name="login" method="POST" className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.inputWrapper}>
          <TextField
            className={styles.formInput}
            label="Email *"
            disableUnderline
            onChange={(e) => onChangeInput('username', e.target.value)}
            error={!!errors.username}
            helperText={
              errors.username?.message && (
                <span className={styles.formError}> • {errors.username?.message}</span>
              )
            }
          />
        </div>
        <div className={styles.inputWrapper}>
          <TextField
            className={styles.formInput}
            label="Password *"
            onChange={(e) => onChangeInput('password', e.target.value)}
            error={!!errors.password}
            helperText={
              errors.password?.message && (
                <span className={styles.formError}> • {errors.password?.message}</span>
              )
            }
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    type="icon"
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setShowPassword((show) => !show);
                    }}
                    onMouseDown={(e) => {
                      e.preventDefault();
                    }}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </div>
        {!errors.password?.message && !errors.username?.message && error && (
          <div className={styles.formError}>
            {error.trim() === 'Authentication exception' ? (
              <p>• Username or password incorrect</p>
            ) : (
              <p>• Server Error</p>
            )}
          </div>
        )}
        <div>
          <div className={styles.formLink} onClick={() => setMode('reset')}>
            Reset password
          </div>
        </div>
        <div className={styles.formBottom}>
          <div className={styles.formLink} onClick={() => setMode('up')}>
            Don`t have an account yet? Sign up
          </div>
          <div className={styles.rightButtons}>
            <button
              type="button"
              className={styles.googleBtn}
              disabled={submitDisable}
              onClick={signInWithGoogle}
            >
              <GoogleIcon />
            </button>
            <Button
              className={styles.formSubmitButton}
              type="primary"
              loading={submitDisable}
              isSubmit
            >
              Sign in
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
