import React from 'react';
import styles from './Oops.module.scss';
import { useNavigate } from 'react-router-dom';
import { Button } from 'tt-ui-lib/core';

const Oops = () => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate('/newassessment', { replace: true });
  };

  return (
    <div className={styles.errorContainer}>
      <div className={styles.errorImage}>
        <div className={styles.errorText}>Sorry, something went wrong</div>
        <Button type="primary" id={styles.errorButton} onClick={onClick}>
          Go to the main
        </Button>
      </div>
    </div>
  );
};

export default Oops;
